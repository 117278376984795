import * as React from "react"
import { graphql } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import Seo from '../components/seo'
import Footer from '../components/footer'
import Header from "../components/header"
import Text from "../components/text"
import Contact from "../components/contact"
import Brands from "../components/brands"
import Products from "../components/products"
import Icons from "../components/icons"
import Heading from "../components/heading"
import Experts from "../components/experts"

const HeatPumpsAndAirConditioningPage = ({data}) => {
  const entry = data.prismicHeatPumpsAndAirConditioningPage
  if (!entry) return null
  return (
    <> 
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image={entry.data.meta_image.url} pathname={linkResolver(entry.data)} />
      <Header logo='ref_air' />
      {entry.data.body.map((slice) => {
        if (slice.slice_type === 'text') {
          return (
            <div key={slice.id}>
              <Text primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'brands') {
          return (
            <div key={slice.id}>
              <Brands primary={slice.primary} items={slice.items} />
            </div>
          )
        }
        if (slice.slice_type === 'contact') {
          return (
            <div key={slice.id}>
              <Contact primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'products') {
          return (
            <div key={slice.id}>
              <Products primary={slice.primary} items={slice.items} />
            </div>
          )
        }
        if (slice.slice_type === 'icons') {
          return (
            <div key={slice.id}>
              <Icons primary={slice.primary} items={slice.items} />
            </div>
          )
        }
        if (slice.slice_type === 'heading') {
          return (
            <div key={slice.id}>
              <Heading primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'experts') {
          return (
            <div key={slice.id}>
              <Experts items={slice.items} />
            </div>
          )
        }
      })}
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicHeatPumpsAndAirConditioningPage {
    data {
      page_title
      meta_description
      meta_image {
        url
      }
      body {
        ... on PrismicHeatPumpsAndAirConditioningPageDataBodyBrands {
          id
          items {
            image1 {
              gatsbyImageData
              alt
            }
          }
          primary {
            heading1
            text1 {
              richText
            }
          }
          slice_type
        }
        ... on PrismicHeatPumpsAndAirConditioningPageDataBodyContact {
          id
          slice_type
          primary {
            heading1
            text1 {
              richText
            }
            background {
              gatsbyImageData
              alt
            }
          }
        }
        ... on PrismicHeatPumpsAndAirConditioningPageDataBodyHeading {
          id
          slice_type
          primary {
            breadcrumb
            button1
            heading1
            text1 {
              richText
            }
            page_link1 {
              url
              uid
              type
            }
            parent {
              url
              uid
              type
              link_type
            }
            image1 {
              alt
              gatsbyImageData
            }
          }
        }
        ... on PrismicHeatPumpsAndAirConditioningPageDataBodyIcons {
          id
          primary {
            tag
          }
          items {
            heading1
            image1 {
              url
              alt
            }
            text1 {
              richText
            }
          }
          slice_type
        }
        ... on PrismicHeatPumpsAndAirConditioningPageDataBodyProducts {
          id
          slice_type
          items {
            heading1
            image1 {
              gatsbyImageData
              alt
            }
            text1 {
              richText
            }
            page_link1 {
              url
              uid
              type
            }
          }
          primary {
            tag
          }
        }
        ... on PrismicHeatPumpsAndAirConditioningPageDataBodyText {
          id
          primary {
            button1
            heading1
            page_link1 {
              url
              uid
              type
            }
            text1 {
              richText
            }
            tag
          }
          slice_type
        }
        ... on PrismicHeatPumpsAndAirConditioningPageDataBodyExperts {
          id
          slice_type
          items {
            name
            position
            image {
              gatsbyImageData
              alt
            }
            phone
            email
          }
        }
      }
    }
  }
}
`

export default HeatPumpsAndAirConditioningPage
